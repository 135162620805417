import React, { useState } from "react";
import Footer from "./Footer";
import ReactTypingEffect from "react-typing-effect";
import { Link } from "react-router-dom";
// import { ReactComponent as AppStoresIcons } from "../assets/logo app white.svg";
import "./Mobile-dev.css";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import Fade from "@material-ui/core/Fade";
import SendIcon from "@material-ui/icons/Send";
import LottieAnimation from "./Lottie";
import MobileLottie from "../animations/MobileLottie.json";
import useWindowDimensions from "./WindowDimensions";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
// import GlitchText from "react-glitch-effect/core/GlitchText";
import * as emailjs from "emailjs-com";
// require("dotenv").config();
const { REACT_APP_TEMPLATE_ID } = process.env;
// emailjs.init(process.env.USER_ID);

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#ffff",
    },
    secondary: {
      main: "#C5B358",
    },
  },
});

const useStylesEmailContent = makeStyles((theme) => ({
  root: {
    fontSize: "1.2em",
    // border: "1px solid ",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "rgba(240,240,240,0.2)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "rgba(245,245,245,0.5)",
    },
    "&$focused": {
      backgroundColor: "rgba(245,245,245,0.5)",
      boxShadow: `rgba(150,150,150) 0 0 10px 2px`,
      // borderColor: "#C5B358",
    },
  },
  input: {
    // color: "#ffff",
    fontSize: "1.1em",
    fontWeight: "300",
    lineHeight: "1.1em",
  },
  focused: {},
}));

function EmailContent(props) {
  const classes = useStylesEmailContent();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

const useStylesInfo = makeStyles((theme) => ({
  root: {
    fontSize: "1.2em",
    // margin: "5%",
    // border: "1px solid ",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "rgba(240,240,240,0.2)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "rgba(245,245,245,0.5)",
    },
    "&$focused": {
      backgroundColor: "rgba(245,245,245,0.5)",
      boxShadow: `rgba(150,150,150) 0 0 10px 2px`,
      // borderColor: "#C5B358",
    },
  },
  input: {
    // color: "#ffff",
    fontWeight: "400",
  },
  focused: {},
}));

function Info(props) {
  const classes = useStylesInfo();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

export default function MobileDev() {
  const [email, setEmail] = useState("");
  const [contactNumber, setNumber] = useState("");
  const [name, setName] = useState("");
  const [emailContent, setContent] = useState(
    "I am interested in your Mobile development services and would like to discuss how my organization can benefit from your offer."
  );
  const [processing, setProcessing] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [buttonState, setButtonState] = useState(true);

  const [emailCheck, setEmailCheck] = useState(false);
  const [numberCheck, setNumberCheck] = useState(false);
  const [nameCheck, setNameCheck] = useState(false);
  // const classes = useStyles();
  function handleNumberCheck(Num) {
    if (!Number.isInteger(parseInt(Num.substr(1))) || Num.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
  function handleEmailCheck(mail) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(mail).toLowerCase())) {
      return false;
    } else {
      return true;
    }
  }
  function handleNameCheck(nameInput) {
    if (nameInput === "") {
      return true;
    } else {
      return false;
    }
  }

  const handleSubmit = () => {
    // console.log(process.env.TEMPLATE_ID);
    // console.log(process.env.USER_ID);
    console.log(REACT_APP_TEMPLATE_ID);
    // e.preventDefault(); // Prevents default refresh by the browser
    const templateParams = {
      Name: name,
      About: "Mobile dev",
      Email: email,
      Phone: contactNumber,
      message: emailContent,
    };
    emailjs
      .send(
        "service_ww5ozzo",
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          setMessageSent(true);
          setProcessing(false);
          setButtonState(false);
          // alert("Message Sent, We will get back to you shortly", result.text);
        },
        (error) => {
          alert("An error occurred, Please try again", error.text);
          setProcessing(false);
        }
      );
  };

  function checksChain(email, contactNumber, name) {
    if (handleEmailCheck(email) === true) {
      setEmailCheck(true);
    } else {
      setEmailCheck(false);
    }
    if (handleNumberCheck(contactNumber) === true) {
      setNumberCheck(true);
    } else {
      setNumberCheck(false);
    }
    if (handleNameCheck(name) === true) {
      setNameCheck(true);
    } else {
      setNameCheck(false);
    }
  }

  const size = useWindowDimensions();
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <div className="Mobile-dev">
          <div className="Mobile-content">
            <ReactTypingEffect
              text={["Mobile development"]}
              cursorRenderer={(cursor) => <h1>{cursor}</h1>}
              displayTextRenderer={(text, i) => {
                return <span>{text}</span>;
              }}
              eraseDelay="10000"
              typingDelay="500"
              className="title-mobile"
              cursorClassName="cursor"
              speed="100"
            />
            <div className="title-line" id="#"></div>
            {/* <div className="top-card"> */}
            <table className="top-subtitles">
              <tr>
                <th>
                  <ul className="subtitles">
                    <li className="question">
                      <DoubleArrowIcon
                        style={{ color: "#ffff", fontSize: "0.7em" }}
                      />{" "}
                      Want to develop a highly scalable app for your{" "}
                      <span className="questions-gold">startup business</span> ?
                    </li>
                    <br></br>
                    <li className="question">
                      <DoubleArrowIcon
                        style={{ color: "#ffff", fontSize: "0.7em" }}
                      />{" "}
                      Want your customers to easily{" "}
                      <span className="questions-gold">look</span>,{" "}
                      <span className="questions-gold">purchase</span> and{" "}
                      <span className="questions-gold">engage</span> with your
                      content on their most used device ?
                    </li>
                  </ul>
                  <div className="Enquire-top">
                    <Popup
                      trigger={
                        <Box clone m={3} pl={3} pr={3} pt={1} pb={1}>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{
                              fontSize: "1.1em",
                            }}
                          >
                            Enquire now
                            <PlayArrowIcon
                              style={{
                                color: "black",
                                fontSize: "1.2em",
                                margin: "0 0 0 4px",
                              }}
                            />
                          </Button>
                        </Box>
                      }
                      modal
                      // nested
                    >
                      {(close) => (
                        <div className="contact-card">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <h1 className="contact-us">Contact us</h1>
                          {/* <form onSubmit={}> */}
                          <Box mr={2} mb={2} clone>
                            <Info
                              className="info-input"
                              m={100}
                              style={{ textAlign: "left" }}
                              placeholder="Name"
                              id="outlined-multiline-flexible"
                              maxRows={1}
                              // value={value}
                              onChange={(e) => setName(e.target.value)}
                              error={nameCheck === true}
                              helperText={nameCheck ? "Field is empty !" : " "}
                              variant="outlined"
                            />
                          </Box>
                          <Box mr={2} mb={2} clone>
                            <Info
                              className="info-input"
                              m={100}
                              style={{ textAlign: "left" }}
                              placeholder="E-mail"
                              id="outlined-multiline-flexible"
                              maxRows={1}
                              // value={value}
                              onChange={(e) => setEmail(e.target.value)}
                              error={emailCheck === true}
                              helperText={
                                emailCheck ? "Please enter a valid email" : " "
                              }
                              variant="outlined"
                            />
                          </Box>
                          <Box mr={2} mb={2} clone>
                            <Info
                              className="info-input"
                              style={{ textAlign: "left" }}
                              placeholder="Contact number"
                              id="outlined-multiline-flexible"
                              maxRows={1}
                              // value={value}
                              onChange={(e) => setNumber(e.target.value)}
                              error={numberCheck === true}
                              helperText={
                                numberCheck
                                  ? "Please enter a valid number"
                                  : " "
                              }
                              variant="outlined"
                            />
                          </Box>

                          <EmailContent
                            style={{ textAlign: "left" }}
                            InputLabelProps={{
                              style: {
                                textOverflow: "ellipsis",
                                fontSize: "1.05em",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "100%",
                                // color: "#fff",
                              },
                            }}
                            fullWidth
                            multiline
                            rows={6}
                            id="outlined-multiline-flexible"
                            maxRows={100}
                            defaultValue={emailContent}
                            onChange={(e) => setContent(e.target.value)}
                            error={emailContent === ""}
                            helperText={
                              emailContent === "" ? "Empty field!" : " "
                            }
                            variant="outlined"
                          />
                          <Box className="button-send" mt={2}>
                            {processing && (
                              <CircularProgress color="secondary" />
                            )}
                            {messageSent && (
                              <Fade in={messageSent}>
                                <CheckCircleOutlineRoundedIcon
                                  style={{
                                    color: "#00E21D",
                                    fontSize: "3em",
                                  }}
                                />
                              </Fade>
                            )}
                            {processing === false && buttonState && (
                              <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={async () => {
                                  // handleEmailCheck(email);
                                  if (
                                    handleNumberCheck(contactNumber) ===
                                      false &&
                                    handleEmailCheck(email) === false &&
                                    emailContent !== "" &&
                                    handleNameCheck(name) === false
                                  ) {
                                    setProcessing(true);
                                    handleSubmit();
                                  }
                                  checksChain(email, contactNumber, name);
                                }}
                              >
                                Send
                                <Box ml={1} mt={0} clone>
                                  <SendIcon
                                    style={{
                                      color: "black",
                                      fontSize: "1.2em",
                                      // margin: "0 0 0 4px",
                                    }}
                                  />
                                </Box>
                              </Button>
                            )}
                          </Box>
                          <p style={{ fontSize: "15px", color: "#4b4b4b" }}>
                            We will use this information to discuss the services
                            you enquired about. Please have a look at our{" "}
                            <Link to="/privacy" style={{ color: "#4b4b4b" }}>
                              Privacy Policy
                            </Link>
                          </p>
                        </div>
                      )}
                    </Popup>
                  </div>
                </th>
                <th>
                  <div className="logoSVG" style={{ padding: "0 15% 0 0" }}>
                    <LottieAnimation
                      lotti={MobileLottie}
                      height={size.width * (25 / 100)}
                      width={size.width * (30 / 100)}
                    />
                  </div>
                </th>
              </tr>
            </table>
            {/* <div className="back-mobile"> */}
            {/* <div class="arriere-mobile"> 
            {/* </div> */}
            {/* </div> */}
          </div>
          <div className="features">
            <table className="feature-table">
              <tr>
                <td className="feature-card">
                  <h1 className="feature-title">
                    <ArrowUpwardIcon
                      style={{
                        color: "#C5B358",
                        fontSize: "1.57em",
                        position: "relative",
                        bottom: "-10px",
                        paddingRight: "5px",
                      }}
                    />
                    <span> </span>
                    Scalable
                  </h1>
                  <p className="feature-content">
                    Our technologies are always ready to be scaled up as your
                    business grows, every component will be designed to work
                    seamlessly with 10 or 1.000.000 users.
                  </p>
                </td>
                <td className="feature-card">
                  <h1 className="feature-title">
                    <ImportantDevicesIcon
                      style={{
                        color: "#C5B358",
                        fontSize: "1.57em",
                        position: "relative",
                        bottom: "-10px",
                        paddingRight: "6px",
                      }}
                    />
                    <span> </span>Cross-platform
                  </h1>
                  <p className="feature-content">
                    We will deliver software that runs on IOS, Android as well
                    as any Internet browser so your app is accessible anywhere,
                    at any time.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="feature-card">
                  <h1 className="feature-title">
                    <PeopleAltOutlinedIcon
                      style={{
                        color: "#C5B358",
                        fontSize: "1.57em",
                        position: "relative",
                        bottom: "-10px",
                        paddingRight: "6px",
                      }}
                    />
                    <span> </span>Support & Management
                  </h1>
                  <p className="feature-content">
                    We will define with you the level of management and
                    maintenance you need so you can fully focus on growing your
                    business.
                  </p>
                </td>
                <td className="feature-card">
                  <h1 className="feature-title">
                    <MultilineChartIcon
                      style={{
                        color: "#C5B358",
                        fontSize: "1.57em",
                        position: "relative",
                        bottom: "-10px",
                        paddingRight: "5px",
                      }}
                    />
                    <span> </span>Analytics
                  </h1>
                  <p className="feature-content">
                    Once your app is up and running, we offer you all the tools
                    to gain in-depth comprehension about your users' behaviour.
                    We also offer <Link to="/AI">machine learning</Link>{" "}
                    services to build powerfull models and predictions.
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <Popup
            trigger={
              <Box clone m={3} pl={3} pr={3} pt={1} pb={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    fontSize: "1.1em",
                  }}
                >
                  Enquire now
                  <PlayArrowIcon
                    style={{
                      color: "black",
                      fontSize: "1.2em",
                      margin: "0 0 0 4px",
                    }}
                  />
                </Button>
              </Box>
            }
            modal
            // nested
          >
            {(close) => (
              <div className="contact-card">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <h1 className="contact-us">Contact us</h1>
                {/* <form onSubmit={}> */}
                <Box mr={2} mb={2} clone>
                  <Info
                    className="info-input"
                    m={100}
                    style={{ textAlign: "left" }}
                    placeholder="Name"
                    id="outlined-multiline-flexible"
                    maxRows={1}
                    // value={value}
                    onChange={(e) => setName(e.target.value)}
                    error={nameCheck === true}
                    helperText={nameCheck ? "Field is empty !" : " "}
                    variant="outlined"
                  />
                </Box>
                <Box mr={2} mb={2} clone>
                  <Info
                    className="info-input"
                    m={100}
                    style={{ textAlign: "left" }}
                    placeholder="E-mail"
                    id="outlined-multiline-flexible"
                    maxRows={1}
                    // value={value}
                    onChange={(e) => setEmail(e.target.value)}
                    error={emailCheck === true}
                    helperText={emailCheck ? "Please enter a valid email" : " "}
                    variant="outlined"
                  />
                </Box>
                <Box mr={2} mb={2} clone>
                  <Info
                    className="info-input"
                    style={{ textAlign: "left" }}
                    placeholder="Contact number"
                    id="outlined-multiline-flexible"
                    maxRows={1}
                    // value={value}
                    onChange={(e) => setNumber(e.target.value)}
                    error={numberCheck === true}
                    helperText={
                      numberCheck ? "Please enter a valid number" : " "
                    }
                    variant="outlined"
                  />
                </Box>

                <EmailContent
                  style={{ textAlign: "left" }}
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      fontSize: "1.05em",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      // color: "#fff",
                    },
                  }}
                  fullWidth
                  multiline
                  rows={6}
                  id="outlined-multiline-flexible"
                  maxRows={100}
                  defaultValue={emailContent}
                  onChange={(e) => setContent(e.target.value)}
                  error={emailContent === ""}
                  helperText={emailContent === "" ? "Empty field!" : " "}
                  variant="outlined"
                />
                <Box className="button-send" mt={2}>
                  {processing && <CircularProgress color="secondary" />}
                  {messageSent && (
                    <Fade in={messageSent}>
                      <CheckCircleOutlineRoundedIcon
                        style={{
                          color: "#00E21D",
                          fontSize: "3em",
                        }}
                      />
                    </Fade>
                  )}
                  {processing === false && buttonState && (
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      onClick={async () => {
                        // handleEmailCheck(email);
                        if (
                          handleNumberCheck(contactNumber) === false &&
                          handleEmailCheck(email) === false &&
                          emailContent !== "" &&
                          handleNameCheck(name) === false
                        ) {
                          setProcessing(true);
                          handleSubmit();
                        }
                        checksChain(email, contactNumber, name);
                      }}
                    >
                      Send
                      <Box ml={1} mt={0} clone>
                        <SendIcon
                          style={{
                            color: "black",
                            fontSize: "1.2em",
                            // margin: "0 0 0 4px",
                          }}
                        />
                      </Box>
                    </Button>
                  )}
                </Box>
                <p style={{ fontSize: "15px", color: "#4b4b4b" }}>
                  We will use this information to discuss the services you
                  enquired about. Please have a look at our{" "}
                  <Link to="/privacy" style={{ color: "#4b4b4b" }}>
                    Privacy Policy
                  </Link>
                </p>
              </div>
            )}
          </Popup>
          <Footer quote="“Any sufficiently advanced technology is indistinguishable from magic.” – Arthur C. Clarke" />
        </div>
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </ThemeProvider>
    </>
  );
}
