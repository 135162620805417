import React from "react";
import { Link } from "react-router-dom";
// import { ReactComponent as ELFlogo } from "../assets/ELF logo lueur.svg";
import "./Footer.css";

export default function Footer({ quote }) {
  return (
    <>
      <div className="footer">
        <p>{quote}</p>
        <p>info@elf-technologies.io</p>
        <p className="company-info">
          ELF Technologies Limited is a company registered in England and Wales
          (Company No. 13459388) 65 London Wall London EC2M 5TU
        </p>
        <br />
        <Link style={{ color: "rgb(224, 224, 224)" }} to="/privacy">
          Privacy Policy
        </Link>
      </div>
    </>
  );
}
