import React from "react";
import lkdImmage from "../assets/LinkedIn_logo_initials.png";

export default function LinkedinErr() {
  return (
    <>
      <div
        style={{
          height: "500%",
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
          textAlign: "center",
          padding: 40,
          zIndex: 100,
        }}
      >
        <img
          src={lkdImmage}
          alt="linkedin-logo"
          style={{ width: 50, marginTop: "10%" }}
        />
        <h2>Sorry.</h2>
        <h3>
          There was a problem connecting to LinkedIn. Please try again later or
          use another login option.
        </h3>
      </div>
    </>
  );
}
