import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ELFlogo } from "../../assets/ELF logo lueur.svg";
import "./Mob-Home.css";
import Particles from "react-particles-js";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import "./Mob-Mobile-dev.css";
import "./Mob-Consulting.css";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import Fade from "@material-ui/core/Fade";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import * as emailjs from "emailjs-com";

import Skill3 from "../../assets/skills3.png";
import Skill4 from "../../assets/skills4.png";
import Skill5 from "../../assets/skills5.png";
import AWapplogo from "../../assets/AW_AppIconRad.png";
import AppStore from "../../assets/AppStoreButton.png";
import GooglePlay from "../../assets/PlayStoreButton.png";
import STEapplogo from "../../assets/logo512.png";

// Web
import ReactJS from "../../assets/REACT.svg";

// Mobile
import ReactNat from "../../assets/REACTNAT.png";
import reactNatFB from "../../assets/react-native-firebase.svg";

//AI
import Python from "../../assets/Python.png";
import Keras from "../../assets/keras.png";
import TFlow from "../../assets/Tf2.png";

// Server
import oAuth2 from "../../assets/oauth-2.png";
import ggCloud from "../../assets/ggCloud.png";
import AWS from "../../assets/AWS.png";
import Azure from "../../assets/Azure.png";
import Firebase from "../../assets/Firebase-logo.png";
import nodeJS from "../../assets/nodejs-icon.svg";
import Express from "../../assets/expressjslogo.png";
import MDB from "../../assets/MDB.png";

// Partners
import Microsoft from "../../assets/Microsoft.png";
import Amazon from "../../assets/amazon.png";
import Google from "../../assets/GGlogo.png";

const webBadges = [[ReactJS, "https://reactjs.org/"]];

const mobileBadges = [
  [ReactNat, "https://reactnative.dev/"],
  // [Firebase, "https://firebase.google.com/"],
  [reactNatFB, "https://rnfirebase.io/"],
];

const aiBadges = [
  [TFlow, "https://www.tensorflow.org/"],
  [Keras, "https://keras.io/"],
  [Python, "https://www.python.org/"],
];

const serverBadges = [
  [nodeJS, "https://nodejs.org/en/"],
  [MDB, "https://www.mongodb.com/"],
  [Express, "https://expressjs.com/"],
  [oAuth2, "https://datatracker.ietf.org/doc/html/rfc6749#page-4"],
  [Firebase, "https://firebase.google.com/"],
  [AWS, "https://aws.amazon.com/"],
  [Azure, "https://azure.microsoft.com/"],
  [ggCloud, "https://cloud.google.com/"],
];

const Partners = [Amazon, Google, Microsoft];

const { REACT_APP_TEMPLATE_ID } = process.env;

const useStylesEmailContent = makeStyles((theme) => ({
  root: {
    fontSize: "1.2em",
    // border: "1px solid ",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "rgba(240,240,240,0.2)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "rgba(245,245,245,0.5)",
    },
    "&$focused": {
      backgroundColor: "rgba(245,245,245,0.5)",
      boxShadow: `rgba(150,150,150) 0 0 10px 2px`,
      // borderColor: "#C5B358",
    },
  },
  input: {
    // color: "#ffff",
    fontSize: "1.1em",
    fontWeight: "300",
    lineHeight: "1.1em",
  },
  focused: {},
}));

function EmailContent(props) {
  const classes = useStylesEmailContent();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

const useStylesInfo = makeStyles((theme) => ({
  root: {
    fontSize: "1.2em",
    // margin: "5%",
    // border: "1px solid ",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "rgba(240,240,240,0.2)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "rgba(245,245,245,0.5)",
    },
    "&$focused": {
      backgroundColor: "rgba(245,245,245,0.5)",
      boxShadow: `rgba(150,150,150) 0 0 10px 2px`,
      // borderColor: "#C5B358",
    },
  },
  input: {
    // color: "#ffff",
    fontWeight: "400",
  },
  focused: {},
}));

function Info(props) {
  const classes = useStylesInfo();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

export default function MobHome() {
  useEffect(() => {}, []);

  const [email, setEmail] = useState("");
  const [contactNumber, setNumber] = useState("");
  const [name, setName] = useState("");
  const [emailContent, setContent] = useState(
    "I am interested in your services and would like to discuss how my organization can benefit from your offer."
  );
  const [processing, setProcessing] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [buttonState, setButtonState] = useState(true);

  const [emailCheck, setEmailCheck] = useState(false);
  const [numberCheck, setNumberCheck] = useState(false);
  const [nameCheck, setNameCheck] = useState(false);
  // const classes = useStyles();
  function handleNumberCheck(Num) {
    if (!Number.isInteger(parseInt(Num.substr(1))) || Num.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
  function handleEmailCheck(mail) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(mail).toLowerCase())) {
      return false;
    } else {
      return true;
    }
  }
  function handleNameCheck(nameInput) {
    if (nameInput === "") {
      return true;
    } else {
      return false;
    }
  }

  const handleSubmit = () => {
    console.log(REACT_APP_TEMPLATE_ID);

    const templateParams = {
      Name: name,
      About: "Home",
      Email: email,
      Phone: contactNumber,
      message: emailContent,
    };
    emailjs
      .send(
        "service_ww5ozzo",
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          setMessageSent(true);
          setProcessing(false);
          setButtonState(false);
          // alert("Message Sent, We will get back to you shortly", result.text);
        },
        (error) => {
          alert("An error occurred, Please try again", error.text);
          setProcessing(false);
        }
      );
  };

  function checksChain(email, contactNumber, name) {
    if (handleEmailCheck(email) === true) {
      setEmailCheck(true);
    } else {
      setEmailCheck(false);
    }
    if (handleNumberCheck(contactNumber) === true) {
      setNumberCheck(true);
    } else {
      setNumberCheck(false);
    }
    if (handleNameCheck(name) === true) {
      setNameCheck(true);
    } else {
      setNameCheck(false);
    }
  }
  // const size = useWindowDimensions();

  return (
    <>
      <div className="Top-home">
        <ELFlogo className="mob-logo" />
        <h1 className="mob-header">
          Tech that focuses on your <span className="business">business</span>.
        </h1>

        <div className="solutions">
          <div className="mob-Mission">
            <div className="mob-Mission-content" id="Mission-content">
              <h1>Eliott Fournet</h1>
              <h2>Lead developer</h2>
              <div className="image-eliott"></div>
              {/* <h2>Frameworks & Libraries:</h2> */}
              {/* <img className="mern-image" src={MERN} alt="MERN stack" /> */}
              <h3>Front-end</h3>
              <img
                src={Skill4}
                style={{ height: 6, margin: 0, position: "relative", top: -10 }}
                alt="skill bar 4"
              />
              <div
                style={{
                  width: "100%",
                  flexDirection: "row",
                  position: "relative",
                  top: -15,
                }}
              >
                <div
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    marginRight: 24,
                    marginTop: 0,
                  }}
                >
                  <h4>web</h4>
                  <div style={{ marginTop: 0 }}>
                    {webBadges.map((badge) => {
                      return (
                        <>
                          <Link>
                            <img
                              style={{ height: 25, paddingRight: 4 }}
                              src={badge[0]}
                              alt={badge[1]}
                            />
                          </Link>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    marginTop: 1,
                  }}
                >
                  <h4>mobile</h4>
                  <div style={{ marginTop: 0 }}>
                    {mobileBadges.map((badge) => {
                      return (
                        <>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={badge[1]}
                          >
                            <img
                              style={{ height: 25, paddingRight: 4 }}
                              src={badge[0]}
                              alt={badge[1]}
                            />
                          </a>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <h3 style={{ marginTop: 0, position: "relative", top: -8 }}>
                Server side
              </h3>
              <img
                src={Skill5}
                style={{ height: 6, margin: 0, position: "relative", top: -17 }}
                alt="skill bar 4"
              />
              <div style={{ marginTop: 0, position: "relative", top: -12 }}>
                {serverBadges.map((badge) => {
                  return (
                    <>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={badge[1]}
                      >
                        <img
                          style={{ height: 25, paddingRight: 4 }}
                          src={badge[0]}
                          alt={badge[1]}
                        />
                      </a>
                    </>
                  );
                })}
              </div>
              <h3 style={{ marginTop: 0, position: "relative", top: -4 }}>
                Machine Learning
              </h3>
              <img
                src={Skill3}
                style={{ height: 6, margin: 0, position: "relative", top: -14 }}
                alt="skill bar 4"
              />
              <div style={{ marginTop: 0, position: "relative", top: -10 }}>
                {aiBadges.map((badge) => {
                  return (
                    <>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={badge[1]}
                      >
                        <img
                          style={{ height: 25, paddingRight: 4 }}
                          src={badge[0]}
                          alt={badge[1]}
                        />
                      </a>
                    </>
                  );
                })}
              </div>
            </div>
            {/* We offer expertise and development work at every step of your technology strategy.  */}
          </div>
          <div style={{ position: "relative", top: -195 }}>
            <Popup
              trigger={
                <Box clone m={0}>
                  <Button
                    variant="contained"
                    // color="#C5B358"
                    style={{
                      backgroundColor: "#C5B358",
                      fontSize: "0.8em",
                      color: "white",
                    }}
                  >
                    Enquire now
                    <PlayArrowIcon
                      style={{
                        color: "white",
                        fontSize: "1.2em",
                        margin: "0 0 0 4px",
                      }}
                    />
                  </Button>
                </Box>
              }
              modal
              // nested
            >
              {(close) => (
                <div className="contact-card">
                  <button className="close" onClick={close}>
                    &times;
                  </button>
                  <h1 className="contact-us">Contact us</h1>
                  {/* <form onSubmit={}> */}
                  <Box mr={2} mb={2} clone>
                    <Info
                      className="info-input"
                      m={100}
                      style={{ textAlign: "left" }}
                      placeholder="Name"
                      id="outlined-multiline-flexible"
                      maxRows={1}
                      // value={value}
                      onChange={(e) => setName(e.target.value)}
                      error={nameCheck === true}
                      helperText={nameCheck ? "Field is empty !" : " "}
                      variant="outlined"
                    />
                  </Box>
                  <Box mr={2} mb={2} clone>
                    <Info
                      className="info-input"
                      m={100}
                      style={{ textAlign: "left" }}
                      placeholder="E-mail"
                      id="outlined-multiline-flexible"
                      maxRows={1}
                      // value={value}
                      onChange={(e) => setEmail(e.target.value)}
                      error={emailCheck === true}
                      helperText={
                        emailCheck ? "Please enter a valid email" : " "
                      }
                      variant="outlined"
                    />
                  </Box>
                  <Box mr={2} mb={2} clone>
                    <Info
                      className="info-input"
                      style={{ textAlign: "left" }}
                      placeholder="Contact number"
                      id="outlined-multiline-flexible"
                      maxRows={1}
                      // value={value}
                      onChange={(e) => setNumber(e.target.value)}
                      error={numberCheck === true}
                      helperText={
                        numberCheck ? "Please enter a valid number" : " "
                      }
                      variant="outlined"
                    />
                  </Box>

                  <EmailContent
                    style={{ textAlign: "left" }}
                    InputLabelProps={{
                      style: {
                        textOverflow: "ellipsis",
                        fontSize: "1.05em",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "100%",
                        // color: "#fff",
                      },
                    }}
                    fullWidth
                    multiline
                    rows={6}
                    id="outlined-multiline-flexible"
                    maxRows={100}
                    defaultValue={emailContent}
                    onChange={(e) => setContent(e.target.value)}
                    error={emailContent === ""}
                    helperText={emailContent === "" ? "Empty field!" : " "}
                    variant="outlined"
                  />
                  <Box className="button-send" mt={2}>
                    {processing && <CircularProgress color="secondary" />}
                    {messageSent && (
                      <Fade in={messageSent}>
                        <CheckCircleOutlineRoundedIcon
                          style={{
                            color: "#00E21D",
                            fontSize: "3em",
                          }}
                        />
                      </Fade>
                    )}
                    {processing === false && buttonState && (
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: "#C5B358" }}
                        onClick={async () => {
                          // handleEmailCheck(email);
                          if (
                            handleNumberCheck(contactNumber) === false &&
                            handleEmailCheck(email) === false &&
                            emailContent !== "" &&
                            handleNameCheck(name) === false
                          ) {
                            setProcessing(true);
                            handleSubmit();
                          }
                          checksChain(email, contactNumber, name);
                        }}
                      >
                        Send
                        <Box ml={1} mt={0} clone>
                          <SendIcon
                            style={{
                              color: "black",
                              fontSize: "1.2em",
                              // margin: "0 0 0 4px",
                            }}
                          />
                        </Box>
                      </Button>
                    )}
                  </Box>
                  <p style={{ fontSize: "15px", color: "#4b4b4b" }}>
                    We will use this information to discuss the services you
                    enquired about. Please have a look at our{" "}
                    <Link to="/privacy" style={{ color: "#4b4b4b" }}>
                      Privacy Policy
                    </Link>
                  </p>
                </div>
              )}
            </Popup>
          </div>
          <h1
            style={{
              color: "#C5B358",
              fontWeight: 500,
              fontSize: 18,
              position: "relative",
              top: -178,
            }}
          >
            Latest Releases
          </h1>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              top: -178,
            }}
          >
            <div
              className="mob-release-card"
              style={{
                display: "flex",
                flexDirection: "column",
                // width: "fit-content",
                alignItems: "center",
                // justifyContent: "center",
                position: "relative",
                backgroundImage:
                  "linear-gradient(to bottom right, rgba(0,65,186,0.7), rgba(0,65,186,0.1))",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  zIndex: 5,
                  fontSize: 14,
                  top: -5,
                  right: -5,
                  backgroundColor: "rgb(197, 179, 88)",
                  padding: "3px 6px",
                  color: "white",
                  borderRadius: 10,
                }}
              >
                <span>CPO & eMSP</span>
              </div>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://stations-e.com/"
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  position: "relative",
                  marginBottom: "6px",
                  textDecoration: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "left",
                    position: "relative",
                  }}
                >
                  <img
                    style={{
                      width: 60,
                      borderRadius: 15,
                      boxShadow: "5px 5px 15px rgba(0,0,0,0.2)",
                    }}
                    src={STEapplogo}
                    alt="app icon"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "3vw",
                    }}
                  >
                    <h2
                      style={{
                        display: "inline-block",
                        fontWeight: 400,
                        textAlign: "left",
                        fontSize: 17,
                        color: "white",
                        marginTop: 0,
                        marginBottom: 0,
                        textDecoration: "underline",
                      }}
                    >
                      Stations-e
                    </h2>
                    {/* <h4
                      style={{
                        display: "inline-block",
                        fontWeight: 300,
                        textAlign: "left",
                        fontSize: 14,
                        color: "white",
                        marginTop: 0,
                        marginBottom: 0,
                        textDecoration: "underline",
                      }}
                    >
                      website ->
                    </h4> */}
                  </div>
                </div>
              </a>
              <div
                style={{
                  display: "flex",
                  marginTop: 3,
                }}
              >
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    padding: "4px 3px",
                    fontSize: 15,
                    width: 230,
                    borderRadius: 10,
                    boxShadow: "5px 5px 5px 5px rgba(150,150,150,0.2)",
                    backgroundColor: "rgba(255,255,255,0.8)",
                    color: "rgb(60,60,60)",
                  }}
                >
                  Supporting Stations-e with the software it needs for its vast
                  EV charging network.
                </p>
              </div>
            </div>
            <div
              className="mob-release-card"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  zIndex: 5,
                  fontSize: 14,
                  top: -5,
                  right: -5,
                  backgroundColor: "rgb(197, 179, 88)",
                  padding: "3px 6px",
                  color: "white",
                  borderRadius: 10,
                }}
              >
                <span>Mobile app</span>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  position: "relative",
                  marginBottom: "5px",
                }}
              >
                <img
                  style={{
                    width: 60,
                    borderRadius: 15,
                    boxShadow: "5px 5px 15px rgba(0,0,0,0.2)",
                  }}
                  src={STEapplogo}
                  alt="app icon"
                />
                <h2
                  style={{
                    display: "inline-block",
                    fontWeight: 400,
                    textAlign: "left",
                    fontSize: 17,
                    marginLeft: "3vw",
                  }}
                >
                  Stations-e
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 8,
                }}
              >
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://apps.apple.com/app/stations-e-easy-ev-charging/id1638342709?platform=iphone"
                >
                  <img
                    style={{ height: 36, marginRight: 2 }}
                    src={AppStore}
                    alt="App Store Button"
                  />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=fr.Stationse.prod"
                >
                  <img
                    style={{ height: 36 }}
                    src={GooglePlay}
                    alt="Play Store Button"
                  />
                </a>
              </div>
            </div>
            <div
              className="mob-release-card"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  zIndex: 5,
                  fontSize: 14,
                  top: -5,
                  right: -5,
                  backgroundColor: "rgb(197, 179, 88)",
                  padding: "3px 6px",
                  color: "white",
                  borderRadius: 10,
                }}
              >
                <span>Mobile app</span>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  position: "relative",
                  marginBottom: "5px",
                }}
              >
                <img
                  style={{
                    width: 60,
                    borderRadius: 15,
                    boxShadow: "5px 5px 15px rgba(0,0,0,0.2)",
                  }}
                  src={AWapplogo}
                  alt="app icon"
                />
                <h2
                  style={{
                    display: "inline-block",
                    fontWeight: 400,
                    textAlign: "left",
                    fontSize: 17,
                    marginLeft: "3vw",
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                >
                  London Afterwork
                  <br />
                  Socials
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 8,
                }}
              >
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://apps.apple.com/us/app/london-afterwork-socials/id1596965687#?platform=iphone"
                >
                  <img
                    style={{ height: 36, marginRight: 2 }}
                    src={AppStore}
                    alt="App Store Button"
                  />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.laws.aw&gl=GB"
                >
                  <img
                    style={{ height: 36 }}
                    src={GooglePlay}
                    alt="Play Store Button"
                  />
                </a>
              </div>
            </div>
          </div>
          <h1
            style={{
              color: "#C5B358",
              fontWeight: 500,
              fontSize: 18,
              position: "relative",
              top: -142,
            }}
          >
            Official Partnerships
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
              top: -129,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {Partners.map((partner) => (
              <div
                style={{
                  width: 110,
                  display: "inline-block",
                }}
              >
                <img style={{ width: 80 }} src={partner} alt="partner logo" />
              </div>
            ))}
          </div>
          <div
            style={{
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              top: 58,
            }}
          >
            <div className="mob-box">
              <div className="mob-main-box">
                <div className="mob-features-home-explained-title">
                  <h1>Solutions</h1>
                </div>
                <div className="mob-features-home-explained">
                  <p>
                    <span className="highlighted">Cutting-edge solutions</span>{" "}
                    that will make your company strive in the fast-changing tech
                    world. We{" "}
                    <span className="highlighted">guide you at every step</span>{" "}
                    defining a strategy, developing your technology and helping
                    you maintain it.
                  </p>
                  <h2>
                    <DoneAllIcon
                      style={{
                        color: "#C5B358",
                        fontSize: "1.57em",
                        position: "relative",
                        bottom: "-5px",
                        paddingRight: "3px",
                      }}
                    />
                    High quality technology, analysis and reporting.
                  </h2>
                  <h2>
                    <DoneAllIcon
                      style={{
                        color: "#C5B358",
                        fontSize: "1.57em",
                        position: "relative",
                        bottom: "-5px",
                        paddingRight: "3px",
                      }}
                    />
                    Focus on efficiency and results.
                  </h2>
                  <h2>
                    <DoneAllIcon
                      style={{
                        color: "#C5B358",
                        fontSize: "1.57em",
                        position: "relative",
                        bottom: "-5px",
                        paddingRight: "3px",
                      }}
                    />
                    Personalized offer adjusted to your objectives.
                  </h2>
                </div>
                <div
                  style={{
                    width: "100%",
                    flexDirection: "column",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      marginBottom: 5,
                    }}
                  >
                    <Link
                      to="/web"
                      style={{
                        display: "inline-block",
                        width: "45%",
                        textAlign: "center",
                        border: "1px solid rgb(220, 220, 220)",
                        borderRadius: 300,
                        paddingTop: 8,
                        paddingBottom: 8,
                        boxShadow: "2px 4px 6px rgba(210, 210, 210, 0.4)",
                        textDecoration: "none",
                        color: "rgb(10, 10, 10)",
                        fontWeight: 300,
                        fontSize: 15,
                        backgroundColor: "white",
                      }}
                    >
                      Web
                    </Link>
                    <Link
                      to="/mobile-dev"
                      style={{
                        display: "inline-block",
                        width: "45%",
                        textAlign: "center",
                        border: "1px solid rgb(220, 220, 220)",
                        boxShadow: "2px 4px 6px rgba(210, 210, 210, 0.4)",
                        textDecoration: "none",
                        color: "rgb(10, 10, 10)",
                        fontWeight: 300,
                        fontSize: 15,
                        borderRadius: 300,
                        paddingTop: 8,
                        paddingBottom: 8,
                        backgroundColor: "white",
                      }}
                    >
                      Mobile
                    </Link>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Link
                      to="/consulting"
                      style={{
                        zIndex: 5,
                        display: "inline-block",
                        width: "45%",
                        textAlign: "center",
                        border: "1px solid rgb(220, 220, 220)",
                        borderRadius: 300,
                        paddingTop: 8,
                        paddingBottom: 8,
                        boxShadow: "2px 4px 6px rgba(210, 210, 210, 0.4)",
                        textDecoration: "none",
                        color: "rgb(10, 10, 10)",
                        fontWeight: 300,
                        fontSize: 15,
                        backgroundColor: "white",
                      }}
                    >
                      Consulting
                    </Link>
                    <Link
                      to="/AI"
                      style={{
                        zIndex: 5,
                        display: "inline-block",
                        width: "45%",
                        textAlign: "center",
                        border: "1px solid rgb(220, 220, 220)",
                        borderRadius: 300,
                        paddingTop: 8,
                        paddingBottom: 8,
                        boxShadow: "2px 4px 6px rgba(210, 210, 210, 0.4)",
                        textDecoration: "none",
                        color: "rgb(10, 10, 10)",
                        fontWeight: 300,
                        fontSize: 15,
                        backgroundColor: "white",
                      }}
                    >
                      ML/AI
                    </Link>
                  </div>
                </div>
                <div className="Enquire-top">
                  <Popup
                    trigger={
                      <Button
                        variant="contained"
                        // color="#C5B358"
                        style={{
                          backgroundColor: "#C5B358",
                          fontSize: "0.7em",
                          position: "relative",
                          marginBottom: 20,
                          color: "white",
                          // top: 25,
                        }}
                      >
                        Enquire now
                        <PlayArrowIcon
                          style={{
                            fontSize: "1.2em",
                            margin: "0 0 0 4px",
                            color: "white",
                          }}
                        />
                      </Button>
                    }
                    modal
                    // nested
                  >
                    {(close) => (
                      <div className="contact-card">
                        <button className="close" onClick={close}>
                          &times;
                        </button>
                        <h1 className="contact-us">Contact us</h1>
                        {/* <form onSubmit={}> */}
                        <Box mr={2} mb={2} clone>
                          <Info
                            className="info-input"
                            m={100}
                            style={{ textAlign: "left" }}
                            placeholder="Name"
                            id="outlined-multiline-flexible"
                            maxRows={1}
                            // value={value}
                            onChange={(e) => setName(e.target.value)}
                            error={nameCheck === true}
                            helperText={nameCheck ? "Field is empty !" : " "}
                            variant="outlined"
                          />
                        </Box>
                        <Box mr={2} mb={2} clone>
                          <Info
                            className="info-input"
                            m={100}
                            style={{ textAlign: "left" }}
                            placeholder="E-mail"
                            id="outlined-multiline-flexible"
                            maxRows={1}
                            // value={value}
                            onChange={(e) => setEmail(e.target.value)}
                            error={emailCheck === true}
                            helperText={
                              emailCheck ? "Please enter a valid email" : " "
                            }
                            variant="outlined"
                          />
                        </Box>
                        <Box mr={2} mb={2} clone>
                          <Info
                            className="info-input"
                            style={{ textAlign: "left" }}
                            placeholder="Contact number"
                            id="outlined-multiline-flexible"
                            maxRows={1}
                            // value={value}
                            onChange={(e) => setNumber(e.target.value)}
                            error={numberCheck === true}
                            helperText={
                              numberCheck ? "Please enter a valid number" : " "
                            }
                            variant="outlined"
                          />
                        </Box>

                        <EmailContent
                          style={{ textAlign: "left" }}
                          InputLabelProps={{
                            style: {
                              textOverflow: "ellipsis",
                              fontSize: "1.05em",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "100%",
                              // color: "#fff",
                            },
                          }}
                          fullWidth
                          multiline
                          rows={6}
                          id="outlined-multiline-flexible"
                          maxRows={100}
                          defaultValue={emailContent}
                          onChange={(e) => setContent(e.target.value)}
                          error={emailContent === ""}
                          helperText={
                            emailContent === "" ? "Empty field!" : " "
                          }
                          variant="outlined"
                        />
                        <Box className="button-send" mt={2}>
                          {processing && <CircularProgress color="secondary" />}
                          {messageSent && (
                            <Fade in={messageSent}>
                              <CheckCircleOutlineRoundedIcon
                                style={{
                                  color: "#00E21D",
                                  fontSize: "3em",
                                }}
                              />
                            </Fade>
                          )}
                          {processing === false && buttonState && (
                            <Button
                              type="submit"
                              variant="contained"
                              style={{ backgroundColor: "#C5B358" }}
                              onClick={async () => {
                                // handleEmailCheck(email);
                                if (
                                  handleNumberCheck(contactNumber) === false &&
                                  handleEmailCheck(email) === false &&
                                  emailContent !== "" &&
                                  handleNameCheck(name) === false
                                ) {
                                  setProcessing(true);
                                  handleSubmit();
                                }
                                checksChain(email, contactNumber, name);
                              }}
                            >
                              Send
                              <Box ml={1} mt={0} clone>
                                <SendIcon
                                  style={{
                                    color: "black",
                                    fontSize: "1.2em",
                                    // margin: "0 0 0 4px",
                                  }}
                                />
                              </Box>
                            </Button>
                          )}
                        </Box>
                        <p style={{ fontSize: "10px", color: "#4b4b4b" }}>
                          We will use this information to discuss the services
                          you enquired about. Please have a look at our{" "}
                          <Link to="/privacy" style={{ color: "#4b4b4b" }}>
                            Privacy Policy
                          </Link>
                        </p>
                      </div>
                    )}
                  </Popup>
                </div>
              </div>
              {/* <div "tooltip">grid-area: hero</div> */}
            </div>
          </div>
          <div
            style={{
              color: "#424242",
              position: "relative",
              // bottom: "50px",
              fontSize: "12px",
            }}
            className="footer"
          >
            <p>"Nothing endures but change." – Heraclitus</p>
            <p>info@elf-technologies.io</p>
            <p className="company-info">
              ELF Technologies Limited is a company registered in England and
              Wales (Company No. 13459388) 65 London Wall London EC2M 5TU
            </p>
            <br />
            <Link style={{ color: "black" }} to="/privacy">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>

      <Particles
        params={{
          particles: {
            number: {
              value: 50,
            },
            size: {
              value: 1,
            },
            line_linked: {
              shadow: {
                enable: true,
                color: "#3CA9D1",
                blur: 5,
              },
            },
          },
        }}
        width={"800"}
        // height={"900"}
        canvasClassName="mob-particles"
      />

      <script src="particles.js"></script>
    </>
  );
}
