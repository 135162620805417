import React from "react";
// import { ReactComponent as ELFlogo } from "../assets/ELF logo lueur.svg";

export default function LAWSprivacy() {
  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          paddingLeft: 15,
          paddingRight: 12,
          paddingTop: 8,
          backgroundColor: "white",
          textAlign: "left",
        }}
      >
        <p
          style={{
            fontSize: 40,
            fontWeight: "600",
            marginBottom: 50,
            paddingTop: 20,
            textAlign: "center",
          }}
        >
          London Afterwork Socials App: Privacy policy
        </p>
        <p style={{ fontSize: 22, fontWeight: "600", marginBottom: 14 }}>
          The type of personal information we collect
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          We currently collect and process the following information:
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          • Personal identifiers, contacts and characteristics including full
          name and personal or work email.
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 40 }}>
          • Other Information: Based on your consent, we may collect other
          information from your device, such as photos from your camera roll or
          calendar information you want to manage via the Platform.
        </p>
        <p style={{ fontSize: 22, fontWeight: "600", marginBottom: 14 }}>
          How we get the personal information and why we have it
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Most of the personal information we process is provided to us directly
          by you for one of the following reasons:
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          • You have registered an account on our App
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 14 }}>
          • You have posted content on our App
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          We use the information that you have given us in order to deliver our
          service to you and other users by logging you into your account and
          displaying the content you post.
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Your personal data may be shared with third parties that are critical
          for the operation of our App such as Amazon Web Services, Firebase and
          Google Cloud. By using our App you agree with their own Terms of
          Service (see our Terms of Service).
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Under the General Data Protection Regulation (GDPR), the lawful bases
          we rely on for processing this information are:
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 40 }}>
          (a) Your consent. You are able to remove your consent at any time. You
          can do this by contacting info@elf-technologies.io.
        </p>
        <p style={{ fontSize: 22, fontWeight: "600", marginBottom: 14 }}>
          How we store your personal information
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Your information is securely stored on servers and databases. This
          includes remote servers and databases provided by Amazon Web Services,
          Firebase and Google Cloud.
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 40 }}>
          We keep all the information you give to us until 5 years after your
          account becomes inactive. We will then dispose of your information by
          deleting them from our databases.
        </p>
        <p style={{ fontSize: 22, fontWeight: "600", marginBottom: 14 }}>
          Your data protection rights
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Your right of access - You have the right to ask us for copies of your
          personal information.
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Your right to rectification - You have the right to ask us to rectify
          personal information you think is inaccurate. You also have the right
          to ask us to complete information you think is incomplete.
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Your right to erasure - You have the right to ask us to erase your
          personal information in certain circumstances.
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Your right to restriction of processing - You have the right to ask us
          to restrict the processing of your personal information in certain
          circumstances.
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Your right to object to processing - You have the right to object to
          the processing of your personal information in certain circumstances.
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Your right to data portability - You have the right to ask that we
          transfer the personal information you gave us to another organisation,
          or to you, in certain circumstances.
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 40 }}>
          You are not required to pay any charge for exercising your rights. If
          you make a request, we have one month to respond to you. Please
          contact us at info@elf-technologies.io if you wish to make a request.
        </p>
        <p style={{ fontSize: 22, fontWeight: "600", marginBottom: 14 }}>
          How to complain
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 12 }}>
          If you have any concerns about our use of your personal information,
          you can make a complaint to us at info@elf-technologies.io. You can
          also complain to the ICO if you are unhappy with how we have used your
          data. The ICO’s address:
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Information Commissioner’s Office, Wycliffe House, Water Lane,
          Wilmslow, Cheshire SK9 5AF
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Helpline number: 0403 123 1113 ICO website: https://www.ico.org.uk
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 40 }}>
          Changes to this Privacy Notice – we review this Privacy Notice
          regularly and will place updates on our App.
        </p>
        <p style={{ fontSize: 22, fontWeight: "600", marginBottom: 14 }}>
          Company Information
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Name: ELF TECHNOLOGIES LIMITED
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Address: 65 London Wall London EC2M 5TU
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", marginBottom: 10 }}>
          Phone number: +33 7 86 00 72 40
        </p>
        <p style={{ fontSize: 15, fontWeight: "400", paddingBottom: 100 }}>
          E-mail: info@elf-technologies.io
        </p>
      </div>
    </>
  );
}
