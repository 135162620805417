import React from "react";
import "./App.css";
import { Route } from "react-router-dom";
import Home from "./Components/Home";
import NavBar from "./Components/NavBar";
import MobileDev from "./Components/Mobile-dev";
import WebDev from "./Components/WebDev";
import MachineLearning from "./Components/MachineLearning";
import Consulting from "./Components/Consulting";
import MobHome from "./Components/Mobile components/Mob-Home";
// import MobNavBar from "./Components/Mobile components/Mob-NavBar";
import MobMobileDev from "./Components/Mobile components/Mob-Mobile-dev";
import MobWebDev from "./Components/Mobile components/Mob-WebDev";
import MobMachineLearning from "./Components/Mobile components/Mob-MachineLearning";
import MobConsulting from "./Components/Mobile components/Mob-Consulting";
import useWindowDimensions from "./Components/WindowDimensions";
import Privacy from "./Components/Privacy";
import LAWSprivacy from "./Components/LAWS-privacy";
import Support from "./Components/LAWS-support";
import LinkedinErr from "./Components/LinedinErr";

function App() {
  const size = useWindowDimensions();
  return (
    <div className="App">
      {/* <div className="App--content"> */}
      {size.width > 690 && (
        <>
          <NavBar />
          <Route exact path="/" render={(props) => <Home {...props} />} />
          <Route exact path="/web" render={(props) => <WebDev {...props} />} />
          <Route exact path="/mobile-dev" render={(props) => <MobileDev />} />
          <Route exact path="/AI" render={(props) => <MachineLearning />} />
          <Route exact path="/consulting" render={(props) => <Consulting />} />
          <Route exact path="/privacy" render={(props) => <Privacy />} />
          <Route exact path="/LAWS-support" render={(props) => <Support />} />
          <Route
            exact
            path="/Linkedin-error"
            render={(props) => <LinkedinErr />}
          />
          <Route
            exact
            path="/LAWS-privacy-policy"
            render={(props) => <LAWSprivacy />}
          />
          <Route
            exact
            path="/verification"
            render={(props) => (
              <>
                <h1
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    color: "white",
                  }}
                >
                  For SMTP verification, thanks for verifying my account, have a
                  great day :)
                </h1>
              </>
            )}
          />
        </>
      )}
      {size.width < 690 && (
        <>
          <NavBar />
          <Route exact path="/" render={(props) => <MobHome {...props} />} />
          <Route
            exact
            path="/web"
            render={(props) => <MobWebDev {...props} />}
          />
          <Route
            exact
            path="/mobile-dev"
            render={(props) => <MobMobileDev />}
          />
          <Route exact path="/AI" render={(props) => <MobMachineLearning />} />
          <Route
            exact
            path="/consulting"
            render={(props) => <MobConsulting />}
          />
          <Route exact path="/privacy" render={(props) => <Privacy />} />
          <Route
            exact
            path="/LAWS-privacy-policy"
            render={(props) => <LAWSprivacy />}
          />
          <Route exact path="/LAWS-support" render={(props) => <Support />} />
          <Route
            exact
            path="/Linkedin-error"
            render={(props) => <LinkedinErr />}
          />
          {/* <Route
            exact
            path="/verification"
            render={(props) => (
              <>
                <h1
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    color: "white",
                  }}
                >
                  For SMTP verification, thanks for verifying my account, have a
                  great day :)
                </h1>
              </>
            )}
          /> */}
        </>
      )}

      {/* </div> */}
    </div>
  );
}

export default App;
