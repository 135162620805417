import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./NavBar.css";

export default class NavBar extends Component {
  render() {
    return (
      <>
        <div className="NavBar">
          <input type="checkbox" className="check" id="checked" />
          <label className="menu-btn" htmlFor="checked">
            <span className="bar top"></span>
            <span className="bar middle"></span>
            <span className="bar bottom"></span>
            <span className="menu-btn__text">MENU</span>
          </label>

          <label className="close-menu" htmlFor="checked"></label>
          <nav className="drawer-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/web">Web development</Link>
              </li>
              <li>
                <Link to="/mobile-dev">Mobile development</Link>
              </li>
              <li>
                <Link to="/AI">AI & Machine Learning</Link>
              </li>
              <li>
                <Link to="/consulting">Consulting</Link>
              </li>
            </ul>
          </nav>
        </div>
      </>
    );
  }
}
