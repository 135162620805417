import React from "react";
// import { Link } from "react-router-dom";
import "./Privacy.css";

export default function Privacy() {
  return (
    <>
      <div className="privacy">
        <div className="privacy-privacy">
          <h1>ELF Technologies Privacy Policy</h1>
          <br />
          <h2>Our contact details:</h2>
          <p className="privacy-content">
            Name: ELF TECHNOLOGIES LIMITED
            <br />
            Address: 65 London Wall London EC2M 5TU
            <br />
            Phone number: +33 7 86 00 72 30
            <br />
            E-mail: info@elf-technologies.io
          </p>
          <br />
          <h2>The type of personal information we collect</h2>
          <p className="privacy-content">
            We currently collect and process the following information:
            <br /> • Personal identifiers, contacts and characteristics
            including full name, phone number and personal or work email.
          </p>{" "}
          <br />
          <h2>How we get the personal information and why we have it</h2>
          <p className="privacy-content">
            Most of the personal information we process is provided to us
            directly by you for one of the following reasons:
            <br /> • You have completed the “contact us” form on our website
            <br />
            <br /> We use the information that you have given us in order to
            contact you back and make you an offer for the service you enquired
            about. <br />
            <br />
            Should you contract with us for one of our services, we may share
            this information with third parties that we may use to build your
            software, including for exemple web hosting providers or third party
            APIs. Should this be the case, we will notify you.
            <br />
            <br /> Under the General Data Protection Regulation (GDPR), the
            lawful bases we rely on for processing this information are:
            <br /> (a) Your consent. You are able to remove your consent at any
            time. You can do this by contacting info@elf-technologies.io. <br />
            (b) We have a contractual obligation. <br />
            (f) We have a legitimate interest.
          </p>
          <br />
          <h2>How we store your personal information</h2>
          <p className="privacy-content">
            Your information is securely stored on our e-mail servers and
            databases.
            <br />
            We keep all the information you give to us for 3 years. We will then
            dispose your information by deleting them from our databases, unless
            you signed a software development contract with us in which case we
            will notify you if it is necessary to extend this period of time.
          </p>
          <br />
          <h2>Your data protection rights</h2>
          <p className="privacy-content">
            <b>Your right of access -</b> You have the right to ask us for
            copies of your personal information. <br />
            <b>Your right to rectification -</b> You have the right to ask us to
            rectify personal information you think is inaccurate. You also have
            the right to ask us to complete information you think is incomplete.{" "}
            <br />
            <b>Your right to erasure -</b> You have the right to ask us to erase
            your personal information in certain circumstances.
            <br />
            <b>Your right to restriction of processing -</b> You have the right
            to ask us to restrict the processing of your personal information in
            certain circumstances.
            <br />
            <b>Your right to object to processing -</b> You have the the right
            to object to the processing of your personal information in certain
            circumstances.
            <br />
            <b>Your right to data portability -</b> You have the right to ask
            that we transfer the personal information you gave us to another
            organisation, or to you, in certain circumstances.
            <br />
            <br />
            You are not required to pay any charge for exercising your rights.
            If you make a request, we have one month to respond to you.
            <br />
            Please contact us at info@elf-technologies.io if you wish to make a
            request.
          </p>
          <br />
          <h2>How to complain</h2>
          <p className="privacy-content">
            If you have any concerns about our use of your personal information,
            you can make a complaint to us at info@elf-technologies.io.
            <br />
            You can also complain to the ICO if you are unhappy with how we have
            used your data.
            <br />
            The ICO’s address:
            <br />
            <br />
            Information Commissioner’s Office
            <br />
            Wycliffe House
            <br />
            Water Lane
            <br />
            Wilmslow
            <br />
            Cheshire
            <br />
            SK9 5AF
            <br />
            <br />
            Helpline number: 0303 123 1113
            <br />
            ICO website: https://www.ico.org.uk
          </p>
        </div>
      </div>
    </>
  );
}
