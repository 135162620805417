import React from "react";
// import { ReactComponent as ELFlogo } from "../assets/ELF logo lueur.svg";
import "./Footer.css";

export default function Support() {
  return (
    <>
      <div style={{ color: "white" }}>
        <h1>For support enquiries, please send an email to:</h1>
        <h2>info@elf-technologies.io</h2>
        <p className="company-info">
          ELF Technologies Limited is a company registered in England and Wales
          (Company No. 13459388) 65 London Wall London EC2M 5TU
        </p>
      </div>
    </>
  );
}
